import React from "react"
import AppBar from "@material-ui/core/AppBar"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import styled from "styled-components"
import env from "../../env-config.json"

const StyledFooterBar = styled(AppBar)`
  background: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.contrastText};
  min-height: 300px;
  text-align: center;
`

const StyledListItem = styled(ListItem)`
  * {
    color: ${props => props.theme.palette.secondary.contrastText};
    text-decoration: none;
  }
  *:hover,
  .active > span {
    color: ${props => props.theme.palette.secondary.contrastText};
    ${props => props.theme.typography.body1};
    font-weight: 500;
    text-decoration: underline;
  }
`
const StyledListItemText = styled(ListItemText)`
  * {
    ${props => props.theme.typography.body1};
    font-weight: 500;
  }
`

const StyledInnerSpacer = styled(Box)`
  height: 2rem;
  ${props => props.theme.breakpoints.up("md")} {
    height: 4.5rem;
  }
`

const StyledOuterSpacer = styled(Box)`
  height: 4rem;
  ${props => props.theme.breakpoints.up("md")} {
    height: 6rem;
  }
`

const GridWrapper = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: auto;
`

export default function Footer({ siteTitle }) {
  return (
    <footer>
      <StyledOuterSpacer />
      <StyledFooterBar position="static">
        <GridWrapper>
          <StyledInnerSpacer />
          <Grid container>
            <Grid item sm={4}>
              <List dense>
                {env.menu
                  .filter(e => !e.secondary)
                  .map(entry => (
                    <ListItem key={entry.title}>
                      <StyledListItem >
                        {entry.href.includes("://") ? (
                          <a
                            href={entry.href}
                            title={entry.title}
                            target={entry.target}
                            rel={entry.rel}
                          >
                            <StyledListItemText primary={entry.title} />
                          </a>
                        ) : (
                          <a
                            href={entry.href}
                            title={entry.title}
                            target={entry.target}
                            rel={entry.rel}
                          >
                            <StyledListItemText primary={entry.title} />
                          </a>
                        )}
                      </StyledListItem>
                    </ListItem>
                  ))}
              </List>
            </Grid>
            <Grid item sm={4}>
              <List dense>
                {env.menu
                  .filter(e => e.secondary)
                  .map(entry => (
                    <ListItem key={entry.title}>
                      <StyledListItem >
                        <a
                          href={entry.href}
                          title={entry.title}
                          target={entry.target}
                          rel={entry.rel}
                        >
                          <StyledListItemText primary={entry.title} />
                        </a>
                      </StyledListItem>
                    </ListItem>
                  ))}
                <ListItem>
                  <StyledListItem >
                    <a title={"Privatsphäre-Einstellungen"}>
                      <StyledListItemText
                        onClick={() => window.UC_UI.showFirstLayer()}
                        primary={"Privatsphäre-Einstellungen"}
                      />
                    </a>
                  </StyledListItem>
                </ListItem>
              </List>
            </Grid>
            <Grid item sm={4}>
              <List dense>
                <StyledListItem >
                  <StyledListItemText
                    primary={`© ${new Date().getFullYear()}  TANKCHECKER.DE`}
                  />
                </StyledListItem>{" "}
                <StyledListItem >
                  <StyledListItemText primary={`Alle Angaben ohne Gewähr.`} />
                </StyledListItem>
              </List>
            </Grid>
          </Grid>
        </GridWrapper>
      </StyledFooterBar>
    </footer>
  )
}
