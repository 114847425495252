import React from "react"
import Box from "@material-ui/core/Box"
import styled from "styled-components"

const StyledBox = styled(Box)`
  height: 1rem;
`

function Spacer() {
  return <StyledBox />
}

export default Spacer
