import React, { useState } from "react"
import styled from "styled-components"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import env from "../../env-config.json"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import IconButton from "@material-ui/core/IconButton"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"
import tankchecker from "../../static/logos/tankchecker.svg"
import Drawer from "@material-ui/core/Drawer"
import List from "@material-ui/core/List"
import Divider from "@material-ui/core/Divider"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import SearchIcon from "@material-ui/icons/Search"
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import TrendingDownIcon from "@material-ui/icons/TrendingDown"
import EventIcon from "@material-ui/icons/Event"
import InvertColorsIcon from "@material-ui/icons/InvertColors"
import SettingsBackupRestoreIcon from "@material-ui/icons/SettingsBackupRestore"
import IsoIcon from "@material-ui/icons/Iso"
import BarChartIcon from "@material-ui/icons/BarChart"
import LocationSearchingOutlinedIcon from "@material-ui/icons/LocationSearchingOutlined"
import CompareArrowsIcon from "@material-ui/icons/CompareArrows"
import HeightIcon from "@material-ui/icons/Height"
import FlashOnIcon from "@material-ui/icons/FlashOn"
import HelpIcon from "@material-ui/icons/Help"
import CallMadeIcon from "@material-ui/icons/CallMade"
import "../gatsby-theme-material-ui-top-layout/pwa-install-popup-ios-content.css"
const LazyPwaIos = React.lazy(() => import("./PwaIos"))
const LazyPwaChrome = React.lazy(() => import("./PwaChrome"))

const Logo = styled.img`
  height: 2rem;
`
const FlexGrow = styled.div`
  flex-grow: 1;
`

const StyledAppBar = styled(AppBar)`
  background: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.secondary.contrastText};

  ${props => props.theme.breakpoints.up("md")} {
    min-height: 100px;
  }
`

const StyledToolbar = styled(Toolbar)`
  max-width: 1200px;
  width: 100%;
  align-self: center;
  ${props => props.theme.breakpoints.up("md")} {
    height: 100px;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`

const StyledDrawer = styled(Drawer)`
  .MuiDrawer-paper {
    background: ${props => props.theme.palette.secondary.dark};
  }
`
const Presentation = styled.div`
  padding: 1rem 2rem;
`

const StyledListItemIcon = styled(ListItemIcon)`
  color: ${props => props.theme.palette.primary.light};
`

const StyledListItem = styled(ListItem)`
  float: left;
  width: auto;
`
const StyledHorizontalListItem = styled(ListItem)`
  width: auto;
  display: inline-block;
  * {
    text-decoration: none;
  }
  *:hover,
  .active > span {
    color: ${props => props.theme.palette.secondary.contrastText};
    ${props => props.theme.typography.h3};
    font-weight: 700;
    text-decoration: underline;
  }
`
const StyledHoriztonalListItemText = styled(ListItemText)`
  * {
    ${props => props.theme.typography.h3};
    color: ${props => props.theme.palette.primary.light};
    font-weight: 700;
  }
`

const StyledCloseIconButton = styled(IconButton)`
  float: right;
  cursor: pointer;
  svg {
    color: ${props => props.theme.palette.primary.light};
  }
`

export default function Header(props) {
  const station = props.station
  const [open, setOpen] = useState(false)
  const theme = useTheme()

  const breakpointSmUp = useMediaQuery(theme.breakpoints.up("sm"))

  const isSSR = typeof window === "undefined"

  function renderIcon(icon) {
    if (!icon) {
      return null
    }
    return {
      SearchIcon: <SearchIcon />,
      TrendingUpIcon: <TrendingUpIcon />,
      TrendingDownIcon: <TrendingDownIcon />,
      EventIcon: <EventIcon />,
      InvertColorsIcon: <InvertColorsIcon />,
      SettingsBackupRestoreIcon: <SettingsBackupRestoreIcon />,
      IsoIcon: <IsoIcon />,
      BarChartIcon: <BarChartIcon />,
      LocationSearchingOutlinedIcon: <LocationSearchingOutlinedIcon />,
      CompareArrowsIcon: <CompareArrowsIcon />,
      HeightIcon: <HeightIcon />,
      FlashOnIcon: <FlashOnIcon />,
      HelpIcon: <HelpIcon />,
      CallMadeIcon: <CallMadeIcon />,
    }[icon]
  }

  return (
    <>
      {!isSSR && (
        <React.Suspense fallback={<div />}>
          <LazyPwaIos />
          <LazyPwaChrome />
        </React.Suspense>
      )}

      <StyledAppBar position="static">
        <StyledToolbar disableGutters>
          {!station && (
            <IconButton
              disableRipple
              disableFocusRipple
              href="/"
              title="TANKCHECKER.DE"
            >
              <Logo
                src={tankchecker}
                alt="TANKCHECKER.DE"
                title="TANKCHECKER.de"
              />
            </IconButton>
          )}
          {station && (
            <IconButton
              disableRipple
              href="/"
              alt="TANKCHECKER.DE"
              title="TANKCHECKER.de"
            >
              <KeyboardBackspaceIcon />
            </IconButton>
          )}
          <FlexGrow></FlexGrow>

          {!breakpointSmUp && (
            <>
              <IconButton
                edge="start"
                color="primary"
                onClick={() => {
                  setOpen(!open)
                }}
              >
                <MenuIcon fontSize="large" />
              </IconButton>
              <StyledDrawer
                classes={{ paper: "MuiDrawer-paper" }}
                anchor={"right"}
                open={open}
                onClose={() => {
                  setOpen(!open)
                }}
              >
                <Presentation
                  role="presentation"
                  onClick={() => {
                    setOpen(!open)
                  }}
                  onKeyDown={() => {
                    setOpen(!open)
                  }}
                >
                  <StyledCloseIconButton
                    onClick={() => {
                      setOpen(!open)
                    }}
                  >
                    <CloseIcon fontSize="large" />
                  </StyledCloseIconButton>
                  <List>
                    {env.menu
                      .filter(e => !e.secondary)
                      .map(entry => (
                        <ListItem
                          key={entry.title}
                          button
                          component="a"
                          href={entry.href}
                          title={entry.title}
                          target={entry.target}
                          rel={entry.rel}
                        >
                          <StyledListItemIcon>
                            {renderIcon(entry.icon)}
                          </StyledListItemIcon>
                          <ListItemText primary={entry.title} />
                        </ListItem>
                      ))}
                  </List>
                  <Divider />
                  <List>
                    {env.menu
                      .filter(e => e.secondary)
                      .map(entry => (
                        <ListItem
                          key={entry.title}
                          button
                          component="a"
                          href={entry.href}
                          target={entry.target}
                          rel={entry.rel}
                          title={entry.title}
                        >
                          <StyledListItemIcon>
                            {renderIcon(entry.icon)}
                          </StyledListItemIcon>
                          <ListItemText primary={""} secondary={entry.title} />
                        </ListItem>
                      ))}
                    <ListItem
                      button
                      component="a"
                      onClick={() => window.UC_UI.showFirstLayer()}
                      title={"Privatsphäre-Einstellungen"}
                    >
                      <StyledListItemIcon>
                        {renderIcon(null)}
                      </StyledListItemIcon>
                      <ListItemText
                        primary={""}
                        secondary={"Privatsphäre-Einstellungen"}
                      />
                    </ListItem>
                  </List>
                </Presentation>
              </StyledDrawer>
            </>
          )}
          {breakpointSmUp && (
            <List dense>
              {env.menu
                .filter(e => e.primary)
                .map(entry => (
                  <StyledListItem key={entry.title}>
                    <StyledHorizontalListItem>
                      <a
                        href={entry.href}
                        title={entry.title}
                        target={entry.target}
                        rel={entry.rel}
                      >
                        <StyledHoriztonalListItemText primary={entry.title} />
                      </a>
                    </StyledHorizontalListItem>
                  </StyledListItem>
                ))}
            </List>
          )}
        </StyledToolbar>
      </StyledAppBar>
    </>
  )
}
